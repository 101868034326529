import React from 'react'
import Seo from '../components/shared/seo'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow: hidden;

  .logo-icon {
    margin-bottom: 24px;
  }
`

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Style>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <p>
        Go back to <Link to="/">Homepage</Link>
      </p>
    </Style>
  </>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
